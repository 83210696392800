import './App.css';
import config from "./coreFIles/config";
import Home from './component/home';
import Products from './component/products';
import Login from './component/login';
import ForgetPassword from './component/ForgetPassword';
import Ordersuccess from './component/Ordersuccess';
import ResetPassword from './component/ResetPassword';
import ResetPWD from './component/ResetPWD';
import Walkthrough from './component/walkthrough';

import { BrowserRouter, Route, Routes } from 'react-router-dom'

	function App() {
		return (
		<BrowserRouter>
			<div>
				<Routes>					
					 <Route path={`${config.baseUrl}`} element={<Products />} />
					 <Route path={`${config.baseUrl}/welcome`} element={<Walkthrough />} />
					 <Route path={`${config.baseUrl}/login`} element={<Login />} />
					  <Route path={`${config.baseUrl}/login/:register`} element={<Login />} />
					 <Route path={`${config.baseUrl}/forget-password`} element={<ForgetPassword />} />
					 <Route path={`${config.baseUrl}/order-success/`} element={<Ordersuccess />} />
					 <Route path={`${config.baseUrl}/reset-password/:token`} element={<ResetPassword />} />
					 <Route path={`${config.baseUrl}/change-password/`} element={<ResetPWD />} />
				</Routes>
			</div>
		</BrowserRouter>
		);
	}


export default App;
